@font-face {
    font-family: "Montserrat";
    src: url("../fonts/montserrat-bold-webfont.woff2") format("woff2"),
        url("../fonts/montserrat-bold-webfont.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto-light-webfont.woff2") format("woff2"),
        url("../fonts/roboto-light-webfont.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto-medium-webfont.woff2") format("woff2"),
        url("../fonts/roboto-medium-webfont.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto-bold-webfont.woff2") format("woff2"),
        url("../fonts/roboto-bold-webfont.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto-webfont.woff2") format("woff2"), url("../fonts/roboto-webfont.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Roboto";
    src: url("../fonts/robotoblack-webfont.woff2") format("woff2"),
        url("../fonts/robotoblack-webfont.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "icomoon";
    src: url("../fonts/icomoon.eot?sdywmp");
    src: url("../fonts/icomoon.eot?sdywmp#iefix") format("embedded-opentype"),
        url("../fonts/icomoon.ttf?sdywmp") format("truetype"), url("../fonts/icomoon.woff?sdywmp") format("woff"),
        url("../fonts/icomoon.svg?sdywmp#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arr-down:before {
    content: "\e900";
}
.icon-arr-right:before {
    content: "\e901";
}
.icon-check:before {
    content: "\e902";
}
.icon-cub:before {
    content: "\e903";
}
.icon-eye:before {
    content: "\e904";
}
.icon-fb:before {
    content: "\e905";
}
.icon-financial:before {
    content: "\e906";
}
.icon-fire:before {
    content: "\e907";
}
.icon-flexibility:before {
    content: "\e908";
}
.icon-in:before {
    content: "\e909";
}
.icon-ins:before {
    content: "\e90a";
}
.icon-tw:before {
    content: "\e90b";
}
.icon-urine-analysis:before {
    content: "\e90c";
}
.icon-weekdays:before {
    content: "\e90d";
}
.icon-youtube:before {
    content: "\e90e";
}
